<template>
  <b-container fluid class="mb-6 mt-6 detail">
    <b-row class="g-2">
      <b-col
        cols="3"
        style="min-height: 800px"
        class="card card-custom p-0 detail__left"
      >
        <div class="card-body p-0">
          <div class="detail__left-thumb">
            <img src="/media/bg/demo-7.jpg" alt="" />
            <div
              class="p-2 d-flex align-items-center detail__left--title"
              style=""
            >
              <p style="z-index: 1" class="font-weight-bold text-white">
                Tổng quan về bệnh đái tháo đường
              </p>
            </div>
          </div>
          <div class="p-4">
            <b-row>
              <b-col>
                <b-button class="btn btn-primary bt-secondary btn-sm">
                  <span class="svg-icon svg-icon-primary m-0">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                    />
                  </span>
                </b-button>
                <b-button class="btn btn-danger btn-sm ml-3">
                  <span class="svg-icon svg-icon-danger m-0">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                    />
                  </span>
                </b-button>
                <b-button
                  class="btn btn-success btn-sm ml-3 w-80-px"
                  type="button"
                  pill
                >
                  Active
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label-light">Rating: </label>
                <span class="svg-icon">
                  <p class="d-flex">
                    <inline-svg src="/media/svg/icons/Neolex/Basic/star.svg" />
                    <b class="pl-1">4.5</b>
                    <span class="pl-1 label-light">(71 đánh giá)</span>
                  </p>
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label-light">Rating: </label>
                <p>MD.001</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label-light">Số lượng bài học </label>
                <p>
                  <span class="svg-icon svg-icon-primary">
                    <inline-svg src="/media/demos/coursedetail.png" /> </span
                  >7 bài học / 110 sections
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label-light">Hiển thị App & gói dịch vụ </label>
                <p><checked :checked="true" /> Hiển thị trên App</p>
                <p><checked :checked="false" /> Hiển thị trên App</p>
                <p><checked :checked="true" />Hiển thị trên App</p>
                <p><checked :checked="true" /> Hiển thị cho thành viên Vàng</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label-light">Thời gian hiệu lực</label>
                <p>Từ 15 / 03 / 2021</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label-light">Cập nhật lần cuối</label>
                <p>06 / 01 / 2021</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label-light">Người cập nhật</label>
                <div class="d-flex">
                  <avatar
                    size="40px"
                    text="Nhi To"
                    src=""
                    :rounded="true"
                  ></avatar>
                  <div class="ml-5">
                    <p class="mb-0">Nhi To</p>
                    <span>nhi.to</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col class="card card-custom" cols="9">
        <v-tabs v-model="currentItem">
          <v-tab>Danh mục bài học</v-tab>
          <v-tab>Đánh giá</v-tab>
          <v-tab>Thống kê</v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentItem">
          <v-tab-item>
            <v-card color="basil" flat>
              <basic-subheader class="pr-0">
                <template v-slot:actions>
                  <b-button
                    class="btn btn-success"
                    type="button"
                    @click="createCourse"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/plus.svg"
                      />
                    </span>
                    Thêm bài học
                  </b-button>
                </template>
              </basic-subheader>
              <v-data-table
                :headers="headers"
                :items="data"
                item-key="id"
                sort-by="name"
                group-by="category"
                class="systems-parameter"
                hide-default-footer
              >
                <template v-slot:header.name="{ header }">
                  <v-icon @click.stop="expandAll"
                    >mdi-{{
                      isExpandedNew
                        ? 'unfold-more-horizontal'
                        : 'unfold-less-horizontal'
                    }}
                  </v-icon>
                  {{ header.text }}
                </template>

                <template
                  v-slot:group.header="{
                    toggle,
                    group,
                    isOpen,
                    headers,
                    items,
                  }"
                >
                  <td>VD01</td>
                  <td class="pl-0 no-background">
                    <div class="d-flex justify-content-between">
                      <div>
                        <v-btn
                          @click="toggle"
                          :ref="group"
                          :data-open="isOpen"
                          icon
                        >
                          <v-icon
                            >mdi-{{
                              isOpen ? 'chevron-down' : 'chevron-up'
                            }}</v-icon
                          >
                        </v-btn>
                        <span class="font-weight-boldest">{{ group }}</span>
                      </div>
                      <action-dropdown
                        :value="group"
                        :show_copy="false"
                        @view="viewItem"
                        @edit="editItem"
                        @delete="deleteItem"
                      >
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="javascript:;"
                            class="navi-link text-primary"
                            @click="createSection"
                          >
                            <span class="menu-icon svg-icon svg-icon-sm">
                              <inline-svg
                                class="svg-icon"
                                src="/media/svg/icons/Neolex/Basic/plus.svg"
                              />
                            </span>
                            <span class="navi-text text-primary"
                              >Tạo section</span
                            >
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="javascript:;"
                            class="navi-link text-primary"
                            @click="createQuiz"
                          >
                            <span class="menu-icon svg-icon svg-icon-sm">
                              <inline-svg
                                class="svg-icon"
                                src="/media/svg/icons/Neolex/Basic/plus.svg"
                              />
                            </span>
                            <span class="navi-text text-primary">Tạo quiz</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-divider></b-dropdown-divider>
                      </action-dropdown>
                    </div>
                  </td>
                  <td colspan="1"><checked :checked="items[0].status" /></td>
                </template>
                <template v-slot:item="{ item, expand, isExpanded }">
                  <tr>
                    <td>{{ item.code }}</td>
                    <td>
                      <div class="d-flex justify-content-between">
                        <span class="d-flex align-items-center">
                          <span class="exercise-icon mr-2"></span>
                          {{ item.name }}</span
                        >
                        <span>
                          <action-dropdown
                            :value="item"
                            :show_copy="false"
                            @view="viewItem"
                            :show_setDefault="false"
                            @edit="editItem"
                            @delete="deleteItem"
                          >
                          </action-dropdown>
                        </span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </b-col>
    </b-row>
    <lesson-modal />
    <section-modal />
    <quiz-modal />
  </b-container>
</template>

<script>
export default {
  name: 'CourseDetail',
  components: {
    'lesson-modal': () => import('./components/LessonModal'),
    'section-modal': () => import('./components/SectionModal'),
    'quiz-modal': () => import('./components/QuizModal'),
  },
  data() {
    return {
      openModal: false,
      isExpandedNew: true,
      isExpandedOld: true,
      headers: [
        {
          text: 'Mã',
          value: 'code',
          width: '10%',
          align: 'center',
          sortable: true,
        },
        { text: '', value: 'category', sortable: true },
        { text: 'Tên bài học', value: 'name', sortable: true },
        { text: 'Hoàn tất', value: 'status', sortable: false, width: '10%' },
      ],
      currentItem: '',
      data: [],
    };
  },

  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('lesson')
        .then(({ data }) => {
          this.data = data || [];
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
    viewItem() {},
    editItem() {},
    deleteItem() {},
    createCourse() {
      this.$bvModal.show('lesson-modal');
    },
    createSection() {
      this.$bvModal.show('section-modal');
    },
    createQuiz() {
      this.$bvModal.show('quiz-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  p {
    margin-bottom: 0;
  }
  &__left {
    &-thumb {
      height: 150px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--title {
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      height: 40px;
      position: absolute;
      bottom: 0;
    }
  }
}
.label-light {
  color: #b5b5c3;
}
.w-80-px {
  width: 80px;
}
</style>
